<script>
import Layout from "@/layouts/main.vue";
import PageHeader from "@/components/page-header";
import CryptoJS from "crypto-js";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Multiselect from "@vueform/multiselect";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Swal from "sweetalert2";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      data: {
      matiere_id: null,
      jour: null,
      heure_debut: null,
      heure_fin: null,
      id: null,  // Ajouter l'ID pour identifier le cours lors de la modification
    },
    isEditing: false,  
      cours: {},
      matters: [],
      programmes: [],
      filiere: {},
      title: "Liste",
      timePicker: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
      },
      days: [
        { value: "lundi", label: "Lundi" },
        { value: "mardi", label: "Mardi" },
        { value: "mercredi", label: "Mercredi" },
        { value: "jeudi", label: "Jeudi" },
        { value: "vendredi", label: "Vendredi" },
        { value: "samedi", label: "Samedi" },
      ],
      timeSlots: [
        "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00"
      ],
      items: [
        { text: "Cours", href: "" },
        { text: "", active: true },
      ],
    };
  },

  validations: {
    data: {
      matiere_id: { required: helpers.withMessage("Choisissez la matière", required) },
      jour: { required: helpers.withMessage("Choisissez un jour", required) },
      heure_debut: { required: helpers.withMessage("Choisissez l'heure de début", required) },
      heure_fin: { required: helpers.withMessage("Choisissez l'heure de fin", required) },
    },
  },

  components: {
    Layout,
    PageHeader,
    Multiselect,
    flatPickr,
  },

  mounted() {
    this.decryptFiliere();
    this.getFacultyMatters();
    this.getScheduledCourses();
  },

  methods: {
    initCreation() {
      this.submited = true;
      if (this.isEditing) {
        this.updateCourse();
      } else {
        this.scheduleCourse();
      }
    },

    scheduleCourse() {
      this.v$.data.$touch();
      if (!this.v$.data.$invalid) {
        this.$store.dispatch("postRequest", { route: "api/seance", data: this.data })
          .then(response => {
            this.getScheduledCourses();
            Swal.fire(response.data.message, "Séance de cours enregistrée!", "success");
          })
          .catch(() => {
            Swal.fire({
              title: "Oops...",
              text: "Il y a un problème!",
              icon: "error",
              confirmButtonClass: "btn btn-primary w-xs mt-2",
              buttonsStyling: false,
              showCloseButton: true,
            });
          });
      }
    },

    updateCourse() {
      this.$store.dispatch("putRequest", {
        route: `api/seance/${this.data.id}`,
        data: this.data,
      })
      .then(response => {
        Swal.fire(response.data.message, "Cours modifié avec succès!", "success");
        this.getScheduledCourses();
        this.isEditing = false;  
      })
      .catch(() => {
        Swal.fire({
          title: "Oops...",
          text: "Il y a un problème lors de la modification!",
          icon: "error",
          confirmButtonClass: "btn btn-primary w-xs mt-2",
          buttonsStyling: false,
          showCloseButton: true,
        });
      });
    },

    getScheduledCourses() {
      this.data.filiere_id = this.filiere.id;
      this.$store.dispatch("getRequest", {
        route: `api/getSeances/${this.data.filiere_id}`,
        data: this.data,
      })
      .then(response => {
        this.programmes = response.data;
      })
      .catch(error => console.log(error));
    },

    getFacultyMatters() {
      this.$store.dispatch("getRequest", {
        route: "/api/getFacultyCourses/" + this.filiere?.id,
        data: this.data,
      })
      .then(response => {
        this.matters = response.data.map(matter => ({
          value: matter.id,
          label: matter.nom_cours,
        }));
      })
      // eslint-disable-next-line no-unused-vars
      .catch(error => console.log("error"));
    },

    decryptFiliere() {
      const encryptedMessage1 = localStorage.getItem("dataF");
      if (!encryptedMessage1) {
        console.error('Aucune donnée trouvée pour "dataF" dans localStorage.');
        return;
      }

      const encryptionKey1 = "Slim#9065";

      try {
        const decrypted1 = CryptoJS.AES.decrypt(encryptedMessage1, encryptionKey1);
        const decryptedMessage1 = decrypted1.toString(CryptoJS.enc.Utf8);

        if (!decryptedMessage1) {
          console.error("Erreur lors du décryptage : le message décrypté est vide ou invalide.");
          return;
        }

        this.filiere = JSON.parse(decryptedMessage1);
        this.data.filiere_id = this.filiere.id;
        this.data.annee_scolaire = localStorage.getItem("selectedYear");

      } catch (error) {
        console.error("Erreur lors du décryptage des données :", error);
      }
    },

    hasCourse(day, time) {
      return this.programmes.some(programme =>
        this.matchDay(programme.jour, day) &&
        this.isTimeInRange(time, programme.heure_debut, programme.heure_fin)
      );
    },

    getCourse(day, time) {
      return this.programmes.find(programme =>
        this.matchDay(programme.jour, day) &&
        this.isTimeInRange(time, programme.heure_debut, programme.heure_fin)
      );
    },

    isTimeInRange(currentTime, startTime, endTime) {
      return currentTime >= startTime && currentTime < endTime;
    },

    matchDay(apiDay, day) {
      const daysMap = {
        lundi: "Lundi",
        mardi: "Mardi",
        mercredi: "Mercredi",
        jeudi: "Jeudi",
        vendredi: "Vendredi",
        samedi: "Samedi",
      };
      return daysMap[apiDay.toLowerCase()] === day;
    },
    editCourse(course) {
    this.data = {
      id: course.id, 
      matiere_id: course.matiere.id,
      jour: course.jour,
      heure_debut: course.heure_debut,
      heure_fin: course.heure_fin,
    };
    
    this.isEditing = true;  
    // this.$nextTick(() => {
    //   const modal = bootstrap.Modal.getInstance(document.querySelector('.bs-example-modal-lg'));
    //   modal.show();
    // });
  },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="row justify-content-between mb-3">
            <div class="col-md-4 mb-3">
              <h4>Emploi du temps de {{ filiere.full_classe?.name }} {{ filiere.group?.toUpperCase() }}</h4>
            </div>
            <div class="col-md-6 mb-3 d-flex justify-content-end">
              <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg">
                <i class="mdi mdi-plus"></i> Cours
              </button>
            </div>
          </div>
          <table class="agenda">
            <thead>
              <tr>
                <th v-for="day in days" :key="day.value">{{ day.label }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="time in timeSlots" :key="time">
                <td v-for="day in days" :key="day.value" :class="{ 'has-course': hasCourse(day.label, time) }">
                  <div v-if="hasCourse(day.label, time)" class="course" @click="editCourse(getCourse(day.label, time))" data-bs-toggle="modal" data-bs-target=".bs-example-modal-lg">
                    <p>{{ getCourse(day.label, time).matiere.nom_cours }}</p>
                    <p>{{ getCourse(day.label, time).heure_debut }} - {{ getCourse(day.label, time).heure_fin }}</p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Modal pour ajouter un cours -->
        <div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg">
            <form @submit.prevent="initCreation">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="myLargeModalLabel">Cours</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-lg-12 mb-3">
                      <label class="form-label">Matière</label>
                      <Multiselect v-model="data.matiere_id" :options="matters" placeholder="Choisissez une matière" />
                      <div v-if="v$.data.matiere_id.$error" class="text-danger">
                        {{ v$.data.matiere_id.$errors[0].$message }}
                      </div>
                    </div>

                    <div class="col-lg-6 mb-3">
                      <label class="form-label">Jour</label>
                      <Multiselect v-model="data.jour" :options="days" label="label" placeholder="Choisissez un jour" />
                      <div v-if="v$.data.jour.$error" class="text-danger">
                        {{ v$.data.jour.$errors[0].$message }}
                      </div>
                    </div>

                    <div class="col-lg-3 mb-3">
                      <label class="form-label">Heure de début</label>
                      <flatPickr v-model="data.heure_debut" :config="timePicker" placeholder="Heure de début" class="form-control" />
                      <div v-if="v$.data.heure_debut.$error" class="text-danger">
                        {{ v$.data.heure_debut.$errors[0].$message }}
                      </div>
                    </div>

                    <div class="col-lg-3 mb-3">
                      <label class="form-label">Heure de fin</label>
                      <flatPickr v-model="data.heure_fin" :config="timePicker" placeholder="Heure de fin" class="form-control" />
                      <div v-if="v$.data.heure_fin.$error" class="text-danger">
                        {{ v$.data.heure_fin.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fermer</button>
                  <button type="submit" class="btn btn-primary">{{ isEditing ? 'Mettre à jour' : 'Ajouter' }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.agenda {
  width: 100%;
  border-collapse: collapse;
}
.agenda th, .agenda td {
  border: 1px solid #ddd;
  padding: 8px;
}
.agenda th {
  background-color: #f2f2f2;
}
.has-course {
  background-color: #dff0d8;
  cursor: pointer;
}
.course p {
  margin: 0;
}

.has-course:hover{
  background-color: #f7e39d;
}
</style>
